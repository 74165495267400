@use "../ornament/utilities/clearfix" as *;
@use "../ornament/utilities/color-set" as *;
@use "../ornament/utilities/font" as *;
@use "../ornament/helpers/rem" as *;
@use "../settings" as *;

$footer-colors: #ccc #333 !default;

$layout-breakpoint: 960px;

.footer {
  @include color-set($footer-colors);
  font-size: rem(0.8);
  padding: $small-space;
  a {
    // TODO: COMPASS
    //@include hover-link;
    &:hover {
      text-decoration: none;
    }
  }
}

.footer--inner {
  height: 100%;
  position: relative;
  width: 100%;
}

.footer--copyright {
  bottom: 0;
  display: block;
  position: absolute;
  right: 0;
}

.footer--logo {
  display: block;
  left: 0;
  position: absolute;
  top: 0;
}

@media screen and(max-width: $layout-breakpoint){

  .footer--copyright {
    position: relative;
    margin-top: 1rem;
  }

  .footer--logo {
    position: relative;
  }
}

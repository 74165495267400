@use "../settings" as *;

$nav-debug: $debug !default;

.navigation-primary {
  > ul > li + li:before {
    content: " | ";
  }
}

.navigation-horizontal {

  ul {
    // TODO: Remove word/letter spacing.
  }

  li {
    display: inline-block;
    // TODO: Restore word/letter spacing.
    vertical-align: top;
  }

  li > a,
  li > span,
  li > button {
    display: inline-block;
    vertical-align: top;
  }

  > ul > li ul {
    left: 0;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 2;
  }

}

.navigation-horizontal--container {
  position: relative;
}

.navigation-dropdown {

  li > a,
  li > span,
  li > button {
    display: inline-block;
    vertical-align: top;
  }

  > ul > li {
    display: inline-block;
    // TODO: Restore text spacing.
    vertical-align: top;
    position: relative;
  }

  > ul > li > ul {
    left: 0;
    position: absolute;
    top: 100%;
    z-index: 2;
  }

  > ul > li > ul > li > ul {
    left: 100%;
    position: absolute;
    top: 0;
    z-index: 2;
  }

}

@if $nav-debug {

  .navigation > ul {
    background: lighten(red, 20%);
  }

  .navigation > ul > li {
    background: red;
  }

  .navigation > ul > li > ul {
    background: lighten(orange, 20%);
  }

  .navigation > ul > li > ul > li {
    background: orange;
  }

  .navigation > ul > li > ul > li > ul {
    background: lighten(yellow, 20%);
  }

  .navigation > ul > li > ul > li > ul > li {
    background: yellow;
  }

}

.report-bottom-nav {
  display: flex;
  gap: 1rem;
}

@use "../ornament/utilities/color-set" as *;
@use "../ornament/utilities/font" as *;
@use "../settings" as *;
@use "../links" as *;

.summary--heading {
  @include color-set($island-colors);
  @include links(false #ffffff $kyn-orange);
  font-size: rem(0.8);
  padding: $x-small-space;
  text-transform: uppercase;
  h2 {
    @include font($bold-font);
  }
  a {
    font-size: rem(0.7);
    text-decoration: none;
  }
}

.summary--table {
  tr {
    > * {
      width: 60%;
    }
    > * + * {
      width: 40%;
    }
  }
}

.summary--three-col-table {
  tr {
    > * {
      width: 60%;
    }
    > * + * {
      width: 20%;
    }
  }
}

@use "../settings" as *;
@use "../ornament/utilities/color-set" as *;
@use "../links" as *;

$island-colors:         $inverse-colors !default;
$island-success-colors: $success-colors !default;
$island-error-colors:        transparent  #9a1515 !default;
$island-error-link-colors:   transparent  #9a1515 !default;
$island-padding:        $small-space    !default;
$island-tight-padding:  $x-small-space  !default;
$island-loose-padding:  $space          !default;

.island {
  @include color-set($island-colors);
  padding: $island-padding;
}

.island-tight {
  padding: $island-tight-padding;
}

.island-loose {
  padding: $island-loose-padding;
}

.island-error,
.island-alert {
  @include color-set($island-error-colors);
  @include links($island-error-link-colors);
  padding: 0;
  &:before {
    background: transparent url("images/island-error.png") center center no-repeat;
    content: " ";
    float: left;
    height: 18px;
    margin-left: -24px;
    width: 18px;
  }
}

.island-success,
.island-notice {
  @include color-set($success-colors);
  border: 1px solid;
}

.island--format {
  @include links($island-link-colors);
}

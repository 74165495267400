.xero_report {
  .report_section td {
    font-size: 110%;
    font-weight: bold;
    padding-top: 1em;
  }
  .report_summary {
    font-weight: bold;
  }
}

////////////////////////////////////////////////////////////////////////////////
//
// This file contains common variables used by ornament.
//
////////////////////////////////////////////////////////////////////////////////



// FONTS SETS
//
//
// Sets to be used with the font mixin.
//
// <family>, <style>, <weight>
//

$header-font:     ("proxima-nova", Helvetica, sans-serif) normal 800; // extra bold
$heading-font:    ("proxima-nova", Helvetica, sans-serif) normal 400; // regular
$subheading-font: ("proxima-nova", Helvetica, sans-serif) normal 600; // semibold
$body-font:       ("proxima-nova", Helvetica, sans-serif) normal 400; // regular
$bold-font:       ("proxima-nova", Helvetica, sans-serif) normal 600; // semibold
$italic-font:     ("proxima-nova", Helvetica, sans-serif) italic 400; // regular italic


// TYPE SIZING
//
//
// The base font size should be set in pixels.
//
// The base line height should be unitless.
//

$base-font-size:          16px;
$base-line-height-ratio:  1.25;
$line-space:              $base-font-size * $base-line-height-ratio;



// THEMES
//
// <background>, <color>, <hover-color>, <hover-background>
//
// TODO: switch background & color: <color>, <background>, <hover-color>, <hover-background>
//

$kyn-orange: #ee5f1f;

//                    background    color         hover color   hover background
$body-colors:         #e7e6df       #93928e       false         false;
$page-colors:         #ffffff       #333333       false         false;
$link-colors:         false         $kyn-orange   $kyn-orange   false;
$island-colors:       #535353       #ffffff       false         false;
$island-link-colors:  false         $kyn-orange   $kyn-orange   false;
$notice-colors:       #999900       #ffffcc       false         false;
$notice-link-colors:  false         #ffffff       #e14f0f       false;
$error-colors:        transparent   #9a1515       false         false;
$error-link-colors:   false         #ffffff       #e14f0f       false;
$success-colors:      #009900       #ccffcc       false         false;
$success-link-colors: false         #ffffff       #e14f0f       false;



// SPACES
//
//
// Setup a spacing system.
//

$spacing-ratio: 1.61803398875; // golden ratio
$spacing-unit:  $line-space;

// TODO: COMPASS
// stole this from TSI
@function pow($base, $exponent) {
  $result: 1;
  
  @if $exponent > 0 {
    @for $i from 1 through $exponent {
      $result: $result * $base;
    }
  } @else if $exponent < 0 {
    @for $i from 1 through -$exponent {
      $result: $result / $base;
    }
  }
  @return $result;

}

$xxx-small-space: $spacing-unit * pow($spacing-ratio, -4);
$xx-small-space:  $spacing-unit * pow($spacing-ratio, -3);
$x-small-space:   $spacing-unit * pow($spacing-ratio, -2);
$small-space:     $spacing-unit * pow($spacing-ratio, -1);
$space:           $spacing-unit * pow($spacing-ratio,  0);
$large-space:     $spacing-unit * pow($spacing-ratio,  1);
$x-large-space:   $spacing-unit * pow($spacing-ratio,  2);
$xx-large-space:  $spacing-unit * pow($spacing-ratio,  3);
$xxx-large-space: $spacing-unit * pow($spacing-ratio,  4);



// FORMS
//
//
// Form specific stuff settings.
//

$form-error-color:    foreground-color($error-colors);
$form-border-radius:  0;



// INPUTS
//
// Input styling.
//

$input-background:      #fff;
$input-border:          1px solid #999;
$input-border-radius:   0;
$input-color:           foreground-color($body-colors);
$input-padding:         0.2em;
$input-textarea-height: $space*5;
$input-width:           20rem;



// DEBUG

$debug: false;



@use "ornament/utilities/color-set" as *;
@use "settings" as *;

$default-link-colors:           $link-colors !default;
$default-link-underline:        true !default;
$default-link-hover-underline:  false !default;

@mixin link($colors: $default-link-colors, $underline: $default-link-underline, $hover-underline: $default-link-hover-underline) {
  @include color-set($colors);
  @if $underline {
    text-decoration: underline;
  } @else {
    text-decoration: none;
  }
  &:hover {
    @if $hover-underline {
      text-decoration: underline;
    } @else {
      text-decoration: none;
    }
  }
}

@mixin links($colors: $default-link-colors, $underline: $default-link-underline, $hover-underline: $default-link-hover-underline) {
  a,
  button {
    @include link($colors, $underline, $hover-underline);
  }
}



@use "../ornament/utilities/clearfix" as *;

.split {
  @include pie-clearfix;
  > * {
    float:left;
    width:50%;
  }
  > * + * {
    text-align:right;
  }
}

.split-3 {
  @include clearfix;
  > * {
    float:left;
    width:percentage(1/3);
  }
  > * + * {
    text-align:center;
  }
  > * + * + * {
    text-align:right;
  }
}

.split-1-2 {
  @include pie-clearfix;
  > * {
    float:left;
    width:30%;
  }
  > * + * {
    text-align:right;
    width:70%;
  }
}

// TODO: COMPASS
@use "../ornament/utilities/clearfix" as *;
@use "../ornament/utilities/color-set" as *;
@use "../ornament/utilities/font" as *;
@use "../ornament/helpers/rem" as *;
@use "../settings" as *;
@use "../components/field" as *;

$layout-debug:            $debug  !default;
$layout-footer-height:    146px   !default;
$layout-container-width:  960px   !default;
$layout-breakpoint:       960px   !default;

// TODO: Rework the class names to have layout-desktop, layout-mobile &
// layout-mobile-open.

// LAYOUT (MOBILE)

@media screen and (max-width: $layout-breakpoint) {

  .layout {
    min-height: 100%;
    display: -ms-grid;
    display: grid;
    grid-template-rows: 1fr auto;
  }

  .layout--mobile-tray {
    display: none;
  }

  .layout--content {
    @include color-set($field-colors);
  }

  .layout--mobile-header {
    svg{
      width:30px;
      height:30px;
    }
  }

  .layout--switch {

  }

  .layout--desktop-header {
    display: none;
  }

  .layout--main {

  }

  .layout--desktop-footer {
    height: unset;
  }

}

// LAYOUT-OPEN (MOBILE)

@media screen and (max-width: $layout-breakpoint) {

  .layout--mobile-header{
    position: relative;
    top:20px;
    left:20px;
  }

  .layout-open .layout--mobile-tray {
    display: block;
    min-height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }

  .layout-open .navigation-mobile{
    transform: translate(0, 0);
  }

}

// LAYOUT (DESKTOP)

@media screen and (min-width: $layout-breakpoint) {

  .layout {
    left: 0;
    min-height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .layout--content {
    margin-bottom: $layout-footer-height;
  }

  .layout--mobile-tray {
    display: none;
  }

  .layout--mobile-header {
    display: none;
  }

  .layout--mobile-footer {
    display: none;
  }

  .layout--desktop-footer {
    bottom: 0;
    height: $layout-footer-height;
    left: 0;
    position: absolute;
    width: 100%;
  }

  .layout--main {
    padding: $large-space 0;
  }

  .layout--container {
    max-width: $layout-container-width;
    margin: 0 auto;
  }

}

.navigation-mobile {
  width: 65%;
  transform: translate(-100%, 0);
  background: white;
  transition: 0.25s ease;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  overflow-y: scroll;
  padding:$space;

  nav {
    display: grid;
    display: -ms-grid;
    row-gap: $small-space;
    margin-top: $small-space;
  }

  svg {
    width:30px;
    height:30px;
    margin-bottom: $small-space;
  }

  a {
    font-size: 1.5rem;
  }

  button {
    position: relative;

    &:before {
      content: "›";
      position: absolute;
      right: 15px;
      margin-top: -4px;
      font-size: 30px;
      transform: rotateZ(90deg);
    }

    &.menu-open:before {
      transform: rotateZ(-90deg);
    }
  }
}
@if $layout-debug {

  .layout {
    background: rgb(191, 191, 191);
  }

  .layout--mobile-tray {
    background: rgb(255, 127, 127);
  }

  .layout--content {
    background: rgb(127, 255, 127);
  }

  .layout--mobile-header {
    background: rgb(127, 127, 255);
  }

  .layout--desktop-header {
    background: rgb(127, 127, 255);
  }

  .layout--main {
    background: rgb(0, 191, 0);
  }

  .layout--desktop-footer {
    background: rgb(127, 127, 255);
  }

}

.expense--table {
  tr {
    > * {
      width: 60%;
    }
    > * + * {
      width: 20%;
    }
  }
}

.expense--totals-table {
  tr {
    > * {
      width: 80%;
    }
    > * + * {
      width: 20%;
    }
  }
}

@use "../settings" as *;

.list-form-buttons {
  > li {
    display: inline-block;
  }
  > li + li {
    margin-left: $small-space;
  }
}

.list-pipe-separated {
  > li {
    display: inline;
  }
  > li + li:before {
    content: "|";
    margin: 0 0.5em;
  }
}

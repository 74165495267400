@use "../settings" as *;

$mapping-selected-color: white;
$mapping-selected-background-color: $kyn-orange;

// map expenses controller index styles
.xero-mapping-container {

  .xero-unmapped-container {
    display: -ms-grid;
    display: grid;
    max-width: 100%;
    grid-template-columns: 1fr auto 1fr;
    -ms-grid-columns: 1fr auto 1fr;
  }
  .xero-categories {
    text-align: right;
  }
  .xero-mapping-button-container {
    margin: auto 1em;

    button {
      border: 1px solid #ccc;
      display: block;
      padding: 0.5em;
      font-weight: bold;
      width: 100%;
      text-align: center;
      margin-bottom: 1em;

      &:hover {
        background-color: $mapping-selected-background-color;
        color: $mapping-selected-color;
      }
    }
  }
  .xero-mappable {
    padding: 2px 4px;
    margin-bottom: 2px;
    display: block;

    &:hover, &:active {
      background-color: #ccc;
    }

    &.xero-mappable__selected {
      background-color: $mapping-selected-background-color;
      color: $mapping-selected-color;
    }
  }
  .hidden-category {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    width: 100%;

    > div {
      padding: 2px 4px;
      width: 40%;
    }
    .xero-account-mapping-actions, .hidden-category-actions {
      width: 20%;
    }
  }

  .xero-mapped-container {
    display: -ms-grid;
    display: grid;
    grid-template-columns: auto auto auto;
    -ms-grid-columns: auto auto auto;

    > div {
      border-bottom: 1px solid #ccc;
      padding: 0.5em 0;
    }

    .invalid-mapping {
      color: red;
    }
  }
}

// map expenses controller edit mapping percentage styles
.xero-account-mapping-percentage-container {
  display: grid;
  grid-template-columns: 4ch 1fr;
  padding: 1em 0;
  grid-gap: 1rem;
}
.xero-account-mapping-percentage {
}

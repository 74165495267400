@use "../headings" as *;

.wysiwyg {

  > * + * {
    margin-top: 16px;
  }

  > * + h1 {
    margin-top: 24px;
  }

  > * + h2 {
    margin-top: 24px;
  }

  > * + h3 {
    margin-top: 24px;
  }

  > h2 {
    @include heading-two;
  }

  > h3 {
    @include heading-three;
    border-bottom: 1px solid;
  }

}

//
// $set: background color color-hover background-hover;
//
@mixin color-set($set) {
  @if (length($set) > 0 and nth($set, 1) != false) {
    background: nth($set, 1);
    &:active,
    &:link,
    &:visited {
      background: nth($set, 1);
    }
  }
  @if (length($set) > 1 and nth($set, 2) != false) {
    color: nth($set, 2);
    &:active,
    &:link,
    &:visited {
      color: nth($set, 2);
    }
  }
  @if (length($set) > 2 and nth($set, 3) != false) {
    &:hover,
    &:active {
      color: nth($set, 3);
    }
  }
  @if (length($set) > 3 and nth($set, 4) != false) {
    &:hover,
    &:active {
      background: nth($set, 4);
    }
  }
}

@use "../ornament/utilities/color-set" as *;
@use "../links" as *;
@use "../settings" as *;
@use "layout" as *;

// MOBILE

@media screen and (max-width: $layout-breakpoint) {
}

// DESKTOP

@media screen and (min-width: $layout-breakpoint) {

  .layout-devise {
    max-width: 380px;
    margin: 0 auto;
  }

  .layout-devise--content {
    @include color-set($page-colors);
    @include links;
    border: 1px solid #d5d4cb;
    padding: $large-space;
  }

}

@use "../ornament/utilities/clearfix" as *;
@use "../ornament/utilities/color-set" as *;
@use "../ornament/utilities/font" as *;
@use "../ornament/helpers/rem" as *;
@use "../settings" as *;
@use "layout" as *;

// MOBILE

@media screen and (max-width: $layout-breakpoint) {
  .layout-application{
    > *+* {
      margin-top: 1rem;
    }

    .layout-application--header {
      padding: 15px $xx-large-space 0;
      margin-top: -30px;
    }

    .layout-application--instructions {
      margin: $space;
    }

    .layout-application--page {
      @include color-set($page-colors);
      border: 1px solid #d5d4cb;
      margin: $space;
      padding: $space;
    }
  }
}

// DESKTOP

@media screen and (min-width: $layout-breakpoint) {

  .layout-application {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .layout-application--header {
    margin-left: 200px;
    position: relative;
  }

  .layout-application--header--progress {
    bottom: 0;
    position: absolute;
    right: 0;
  }

  .layout-application--body {
    display: flex;
  }

  .layout-application--instructions {
    margin-right: 1px;
    border-top: 1px solid #d5d4cb;
    width: 199px;
  }

  .layout-application--page {
    @include color-set($page-colors);
    border: 1px solid #d5d4cb;
    padding: $space $large-space;
    width: 760px;
  }

}

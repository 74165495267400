@use "ornament/utilities/font" as *;
@use "ornament/helpers/rem" as *;
@use "settings" as *;

@mixin heading-one {
  @include font($bold-font);
  font-size: rem(2);
}

@mixin heading-two {
  @include font($heading-font);
  font-size: rem(1.8);
}

@mixin heading-three {
  @include font($heading-font);
  font-size: rem(1.2);
}

@mixin heading-four {
  @include font($subheading-font);
}

@mixin heading-five {
  @include font($subheading-font);
}

@mixin heading-six {
  @include font($bold-font);
}

@mixin headings {
  h1 { @include heading-one; }
  h2 { @include heading-two; }
  h3 { @include heading-three; }
  h4 { @include heading-four; }
  h5 { @include heading-five; }
  h6 { @include heading-six; }
}



@use "../spacing" as *;

.form {
  @include spacing();
  > :first-child + * {
    margin-top: 0;
  }
}



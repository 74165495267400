@use "../ornament/utilities/link-colors" as *;
@use "../settings" as *;

$tooltip-background:    $kyn-orange;
$tooltip-padding:       10px;

.tooltip {
  @include link-colors(#535353, $kyn-orange, #535353, #535353, #535353);
  background: #e7e6df;
  border-radius: 10px;
  display: inline-block;
  line-height: 20px;
  padding: 1px 0 0;
  text-align: center;
  text-decoration: none;
  width: 20px;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}

.tippy-box[data-theme~='orange'] {
  background-color: $tooltip-background;
  padding: $tooltip-padding;
  font-size: $base-font-size;
}

.tippy-box[data-theme~='orange'][data-placement^='top'] > .tippy-arrow::before {
  border-top-color: $tooltip-background;
}
.tippy-box[data-theme~='orange'][data-placement^='bottom'] > .tippy-arrow::before {
  border-bottom-color: $tooltip-background;
}
.tippy-box[data-theme~='orange'][data-placement^='left'] > .tippy-arrow::before {
  border-left-color: $tooltip-background;
}
.tippy-box[data-theme~='orange'][data-placement^='right'] > .tippy-arrow::before {
  border-right-color: $tooltip-background;
}

@use "../ornament/utilities/font" as *;
@use "../ornament/utilities/color-set" as *;
@use "../ornament/helpers/rem" as *;
@use "../settings" as *;

$button-colors:           false   $kyn-orange $kyn-orange !default;
$button-dangerous-colors: false   #9a1515 #9a1515 !default;
$button-secondary-colors: false   #535353 #535353 !default;

.button {
  @include color-set($button-colors);
  @include font($body-font);
  display: inline-block;
  background: transparent url("images/button.png") left center no-repeat;
  border: none;
  font-size: rem(0.9);
  line-height: 25px;
  padding-left: 30px;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.button-spacing {
  margin-bottom: 8px;
}

.button-dangerous {
  @include color-set($button-dangerous-colors);
}

.button-secondary {
  @include color-set($button-secondary-colors);
  background-image: url("images/button-secondary.png");
//  &:hover {
//    @include color-set($button-secondary-colors);
//  }
}

.button-tick {
  background: transparent url("images/button-tick.png") left center no-repeat;
}

.button-next {
  background: transparent url("images/button-next.png") left center no-repeat;
}

.button-print {
  background: transparent url("images/button-print.png") left center no-repeat;
}

.button-small {
  font-size: rem(0.8);
  padding: 0.2em 0.5em;
}

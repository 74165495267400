@use "../headings" as *;

.heading-one {
  @include heading-one;
}

.heading-two {
  @include heading-two;
}

.heading-three {
  @include heading-three;
  border-bottom: 1px solid;
}

.heading-four {
  @include heading-four;
}

* + .heading-four {
  margin-top: 1.5em;
}

.heading-five {
  @include heading-five;
}

.heading-six {
  @include heading-six;
}

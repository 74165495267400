// Replacing Compass's clearfix

@mixin clearfix {
  overflow: hidden;
}

@mixin pie-clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

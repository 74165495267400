@use "settings" as *;

$default-spacing-size: $small-space !default;

@mixin spacing-natural {
  > * + * {
    margin-top: $small-space;
  }
  > * + h1 {
    margin-top: $large-space;
  }
  > * + h2 {
    margin-top: $large-space;
  }
  > * + h3 {
    margin-top: $space;
  }
  > * + h4 {
    margin-top: $space;
  }
}

@mixin spacing($size: $default-spacing-size) {
  > * + * {
    margin-top: $size;
  }
}



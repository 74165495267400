////////////////////////////////////////////////////////////////////////////////
//
// This file imports the compass reset, then does some additional resetting.
//
////////////////////////////////////////////////////////////////////////////////


// TODO: COMPASS
//@use "compass/css3/box-sizing";
//@use "compass/reset";

// Added in matching reset from v2 + box-sizing
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, code, del, dfn, em, img, q, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, dialog, figure, footer, header, hgroup, nav, section {margin:0;padding:0;border:0;font-style:inherit;font-size:100%;font-family:inherit;vertical-align:baseline; box-sizing: border-box}

// BOX SIZING
//
//
// Make all elements use border-box.
//

* {
  // TODO: COMPASS
  //@include box-sizing(border-box);
  // TODO: Include this:
  //*behavior: url(/assets/boxsizing.htc);
}



// BUTTON
//
//
// Reset buttons to act like links.
//

button {
  -webkit-appearance: inherit;
  -webkit-box-align: inherit;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: inline;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font: inherit;
  height: auto;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-align: inherit;
  text-transform: inherit;
}



// FORCED DOCUMENT HEIGHT
//
//
// BODY & HTML elements should always be 100% of the viewports height and width.
//

html, body {
  height: 100%;
  width: 100%;
}



// IMPROVED RENDERING
//
//
// Text antialiasing and image interpolation.
//

html {
  -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6 {
  text-rendering: optimizeLegibility;
}

img {
  -ms-interpolation-mode: bicubic;
}



// RESPONSIVE MEDIA
//
//
// Make image, object & iframes scale down when they get squished.
//

img, embed, object, video, iframe {
  max-width: 100%;
}

ol, ul {
  list-style: none;
  padding: 0;
}


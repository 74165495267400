@use "../ornament/utilities/clearfix" as *;
@use "../ornament/utilities/color-set" as *;
@use "../ornament/helpers/rem" as *;
@use "../settings" as *;

$mobile-breakpoint: 40rem;

.dashboard {
  display: -ms-grid;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: percentage(20/694);

  @media screen and (max-width: $mobile-breakpoint) {
    grid-template-columns: 1fr;

    > *+*{
      margin-top: percentage(20/694);
    }
  }
}

.dashboard--heading {
  border-bottom: 1px solid;
  text-transform: uppercase;
  padding: rem(0.5) 0;
}

.dashboard--tabs {
  @include clearfix;
  border-bottom: 1px solid;
  text-transform: uppercase;
  > li {
    float: left;
    > * {
      @include color-set(false inherit inherit);
      display: block;
      padding: rem(0.5) 0;
      text-decoration: none;
    }
  }
  > .dashboard--tabs--selected > * {
    padding-bottom: rem(0.5) - 2px;
    border-bottom: 2px solid $kyn-orange;
  }
  > li + li {
    margin-left: 1em;
  }
}

.dashboard--tab-pane--hidden {
  display: none;
}

////////////////////////////////////////////////////////////////////////////////
//
// This file applys some default styles based ont he settings provided.
//
////////////////////////////////////////////////////////////////////////////////

// TODO: COMPASS
//@use "compass/typography/links/hover-link";
@use "utilities/link-colors" as *;
@use "utilities/color-set" as *;
@use "utilities/font" as *;
@use "../settings" as *;


// BACKGROUND & COLOUR
//
//
// Setup background & colour on the body.
//

body {
  @include color-set($body-colors);
}



// BASE FONT
//
//
// Setup the base font size & line-height.
//

html {
  @include font($body-font);
  font-size: $base-font-size;
  line-height: $base-line-height-ratio;
}



// STRONG & EM, BOLD & ITALIC
//
//
// Set the defaults for bold and italic in a font-face friendly way.
//

strong, b {
  @include font($bold-font);
}

em, i {
  @include font($italic-font);
}



// LINKS
//
//
// Link colors & hover effects.
//

a, button {
  // TODO: COMPASS
  //@include hover-link;
  @include link-colors(nth($link-colors, 2), nth($link-colors, 3), nth($link-colors, 2), nth($link-colors, 2), nth($link-colors, 2));
}



// TABLES
//
//
// Bold TH's for tables.
//

th {
  @include font($bold-font);
}



// FORMS & INPUTS
//
//
// Minimal form cleanup.
//

fieldset {
  display: inline-block;
  width: 100%;
}

legend {
  display: none;
}

[type="text"], [type="password"], [type="date"], [type="datetime"],
[type="email"], [type="number"], [type="search"], [type="tel"], [type="time"],
[type="url"], textarea {
  border-radius: $input-border-radius; // Reset for Safari iOS
  background: $input-background;
  border: $input-border;
  color: $input-color;
  max-width: $input-width;
  padding: $input-padding;
  width: 100%;
}

input, select {
  vertical-align: middle;
}

input, textarea, keygen, select {
  display: inline-block; // What's this do?
  font-size: $base-font-size;
}

textarea {
  height: $input-textarea-height;
  overflow: auto; // Remove scrollbar for text areas in IE.
}

[type="radio"], [type="checkbox"], [type="submit"] {
  cursor: pointer;
}

[type="submit"] {
  line-height: inherit;
  margin: 0;
}



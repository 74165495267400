$default-list-padding: 2em !default;

@mixin list-ordered($padding: $default-list-padding) {
  list-style-type: decimal;
  padding-left: $padding;
}

@mixin list-unordered($padding: $default-list-padding) {
  list-style-type: disc;
  padding-left: $padding;
}

@mixin lists($padding: $default-list-padding) {
  ul {
    @include list-unordered($padding);
  }
  ol {
    @include list-ordered($padding);
  }
}



@use "../ornament/utilities/clearfix" as *;
@use "../ornament/utilities/color-set" as *;
@use "../ornament/utilities/font" as *;
@use "../ornament/helpers/rem" as *;
@use "../settings" as *;

$header-colors: #000 #fff !default;

.header {
  @include color-set($header-colors);
  @include font($header-font);
  font-size: 15px;
  padding: $x-small-space 0;
  text-transform: uppercase;
  a {
    text-decoration: none;
    @include color-set(transparent #fff #e14f0f);
    &:hover {
      text-decoration: none;
    }
  }
}

////////////////////////////////////////////////////////////////////////////////
//
// This is the base CSS file for your application. Import this into your layout.
//
////////////////////////////////////////////////////////////////////////////////



// ORNAMENT
//
// Apply Compass & Ornament resets, apply default Ornament styles, import helper
// functions and import the fonts, settings & headings.
//

@use "ornament/core";



// COMPONENTS
//
//
// Import the components this project uses. Instead of importing Compass or
// Ornament utilities/mixins here, import them directly into your components
//

@use "components/accessibility";
@use "components/button";
@use "components/dashboard";
@use "components/expense";
@use "components/field";
@use "components/footer";
@use "components/format";
@use "components/form";
@use "components/header";
@use "components/heading";
@use "components/island";
@use "components/layout";
@use "components/layout-application";
@use "components/layout-devise";
@use "components/list";
@use "components/navigation";
@use "components/patch";
@use "components/progress";
@use "components/split";
@use "components/summary";
//@use "components/styleguide";
@use "components/table";
@use "components/tooltip";
@use "components/wysiwyg";
@use "components/toggle";
@use "components/xero_report";
@use "components/xero_mappings";
@use "nvd3";

// OVERRIDES
//
//
// Add any hacky or temporary styles here.
//

//  /* Client wanted the logo bigger ASAP... */
//  .header--logo {
//    height:200%;
//    width:200%;
//  }
//

#chart svg {
  height: 400px;
  width:  700px;
}

#long_chart svg {
  height: 600px;
  width:  700px;
}

#switch_user_identifier {
  width: 100%;
}

// TODO: COMPASS
//@use "compass/typography/text/replacement";
@use "../ornament/utilities/clearfix" as *;
@use "../ornament/utilities/color-set" as *;
@use "../settings" as *;
@use "../links" as *;

.patch-form {
  //
}

.patch-form--heading {
  @include color-set($island-colors);
  cursor: pointer;
  padding: 0.5em;
  position: relative;
}

.patch-form--toggle {
  display: inline-block;
  // TODO: COMPASS
  //@include replace-text("components/patch-form--toggle.png");
  background-image: url("images/patch-form--toggle.png");
  font-size: 0;
  float: left;
  margin-right: 5px;
  width: 14px;
  height: 14px;
}

.patch-form--toggle-open {
  background-image: url("images/patch-form--toggle-open.png");
}

.patch-form--delete {
  @include link(false #535353 $kyn-orange, false, true);
  font-size: 2em;
  position: absolute;
  right: -20px;
  top: 0;
  &:hover {
    text-decoration: none;
  }
}

.patch-form--fields {
  @include pie-clearfix;
}

.patch-form--fields--left {
  float: left;
  width: percentage(338/696);
}

.patch-form--fields--right {
  float: left;
  margin-left: percentage(20/696);
  width: percentage(338/696);
}

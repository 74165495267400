@use "../ornament/utilities/font" as *;
@use "../ornament/helpers/rem" as *;
@use "../settings" as *;
@use "../links" as *;

$table-border: 1px solid #ccc !default;

.table {
  td,
  th {
    text-align: left;
    padding: rem(0.5) rem(1);
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  th {
    @include font($body-font);
  }
  tfoot {
    th,
    td {
      @include font($bold-font);
    }
  }
  thead {
    text-transform: uppercase;
    font-size: rem(0.8);
    th {
      @include font($bold-font);
    }
  }
}

.table--error {
  color: #9a1515;
}

.table-ruled {
  td,
  th {
    border-bottom: $table-border;
  }
}

.table-full-width {
  width: 100%;
}

.table-small {
  font-size: rem(0.8);
  td,
  th {
    padding: rem(0.1) rem(0.2);
  }
}

.table-2-cols {
  tr > * {
    width: 50%;
  }
}

.table-3-cols {
  tr > * {
    width: 50%;
  }
  tr > * + * {
    width: 25%;
  }
}

.table--actions {
  @include links($underline: false, $hover-underline: true);
  color: $kyn-orange;
  font-size: rem(0.8);
  text-transform: uppercase;
}

@use "../ornament/utilities/clearfix" as *;
@use "../ornament/utilities/color-set" as *;
@use "../ornament/utilities/font" as *;
@use "../ornament/helpers/rem" as *;
@use "../settings" as *;

$field-colors: #e7e6df #333333 !default;

.field {

}

.field--label {
  display: block;
  abbr {
    @include color-set($error-colors);
    float: left;
    margin-left: -0.5em;
  }
}

.field--hint {
  color: #666;
  font-size: rem(0.8);
}

.field--error {
  @include color-set($error-colors);
  @include font($bold-font);
  font-size: rem(0.8);
  text-transform: uppercase;
}

.field.password,
.field.email,
.field.string {
  input {
    @include color-set($field-colors);
    @include font($body-font);
    border: none;
    padding: 0.5em;
    margin: 0;
  }
}

.field-reversed {
  .field--content {
    @include pie-clearfix;
    > * {
//    input,
//    select {
      float:left;
      width: percentage(1/3);
    }
    > * + * {
//    .field--label {
//      float:left;
      padding: 0.5em;
      width: percentage(2/3);
    }
  }
  .field--label abbr {
    float: none;
    margin-left: 0;
  }
}



@use "../settings" as *;

.progress {
  padding: $small-space 0;
  > li + li {
    margin-top: $x-small-space;
  }
}

.progress--incomplete {
  background: transparent url("images/progress--incomplete.png") left center no-repeat;
  line-height: 40px;
  padding-left: 45px;
}

.progress--complete {
  background: transparent url("images/progress--complete.png") left center no-repeat;
  line-height: 40px;
  padding-left: 45px;
}

.progress-mini {
  font-size: rem(1.5);
  > li {
    display: inline;
  }
}

.progress-mini--complete {
  color: $kyn-orange;
}
